<template>
    <div class="rb-view">
        user detail
    </div>
</template>

<script>
    export default {
        name: "user-detail"
    }
</script>

<style scoped>

</style>
